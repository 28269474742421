import React from "react";

import img_phd from '../assets/images/phd.png';
import img_space_dao from '../assets/images/SpaceDAO.png';
import img_fril_work from '../assets/images/fril-work.png';
import img_esa_fed_learning from '../assets/images/esa-fed-learning.png';
import img_osip from '../assets/images/OSIP.png';
import img_crop_dataset from '../assets/images/EOCropDataset.png';
import img_chainlab from '../assets/images/chainlab.png';
import img_indexer from '../assets/images/indexer.png';
import img_matrixmaths from '../assets/images/matrixmaths.jpg';
import img_goga from '../assets/images/goga.jpg';
import img_nbodyml from '../assets/images/nbodyml.png';
import '../assets/styles/Project.scss';

type ProjectSection = {
    title: string;
    description: string;
    website: string;
    img_ref: string;
  };

function Project() {

    const projectData: ProjectSection[] = [
        {
            title: "Distributed Ledger Technologies for Satellite-based Emergency Mapping",
            description: "My PhD work is all published open source as well as code on github and datasets through multiple streams.",
            website: "https://github.com/strath-ace/smart-dao",
            img_ref: img_phd,
        },
        {
            title: "Space DAO (Decentralised Autonomous Orbit)",
            description: "The goal of this open-source project is to decentralise and automate the current process of Space Situational Awareness data delivery for Satellite Traffic Management and Satellite-based Emergency Mapping data production.",
            website: "https://spacedao.ai/",
            img_ref: img_space_dao,
        },
        {
            title: "Oil Rig Regulatory Monitoring with Satellite data",
            description: "Monitoring North Sea Oil Platforms and FPSOs to understand the actual impact of Oil and Gas regulations on their emissions.",
            website: "",
            img_ref: img_fril_work,
        },
        {
            title: "Federated Learning for Private Crop Yield Estimation",
            description: "European Space Agency Φ-lab research and development project to create a Decentralised Federated Learning system for privatised copy yield estimation and to understand the effects of scale on such a protocol.",
            website: "https://www.nature.com/articles/s41598-025-94244-2",
            img_ref: img_esa_fed_learning,
        },
        {
            title: "ESA OSIP Project: Cognitive Cloud Computing in Space (C3S) Campaign",
            description: "This project was part of the Cognitive Cloud Computing in Space Tender with the European Space Agency.",
            website: "https://www.esa.int/Enabling_Support/Preparing_for_the_Future/Discovery_and_Preparation/ESA_extends_AI_and_cloud_computing_to_space",
            img_ref: img_osip,
        },
        {
            title: "EO Datasets for World Crop Mapping",
            description: "Earth Observation datasets across different regions for crop type and crop yield mapping",
            website: "https://huggingface.co/0x365",
            img_ref: img_crop_dataset,
        },
        {
            title: "Chain-Lab - A sandbox for developing smart contracts",
            description: "A frontend and backend sandbox to help with smart contract development on any language that allows Solidity to execute.",
            website: "https://github.com/0x365/chain-lab",
            img_ref: img_chainlab,
        },
        {
            title: "Blockchain Indexer for Research and Applications",
            description: "Simple and highly adaptable blockchain indexer or data cacher for a variety of blockchains based around EVM.",
            website: "https://github.com/0x365/blockchain-indexer",
            img_ref: img_indexer,
        },
        {
            title: "Matrix Maths for Solidity",
            description: "A Solidity library for doing matrix maths. This is difficult as Solidity does not allow floating point numbers",
            website: "https://github.com/0x365/solidity-matrix-inversion",
            img_ref: img_matrixmaths,
        },
        {
            title: "Genetic Algorithms in Golang",
            description: "A Golang library for Genetic Algorithms",
            website: "https://github.com/0x365/goga",
            img_ref: img_goga,
        },
        {
            title: "Automatic n-body problem orbit finder",
            description: "A method to find different and new orbit shapes in different n-body scenarios",
            website: "https://github.com/0x365/n-body-ml",
            img_ref: img_nbodyml,
        },
      ];

    return(
        <div className="projects-container" id="projects">
            <h1>Projects</h1>
            <div className="projects-grid">
                {projectData.map((item, index) => (
                    <div className="project" key={index}>
                        <a href={item.website} target="_blank" rel="noreferrer">
                            <div className="project-img" style={{ backgroundImage: `url(${item.img_ref})` }}></div>
                        </a>
                        <a href={item.website} target="_blank" rel="noreferrer"><h2>{item.title}</h2></a>
                        <p>{item.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Project;