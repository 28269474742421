// import React from "react";
import '@fortawesome/free-regular-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBriefcase } from '@fortawesome/free-solid-svg-icons';
import 'react-vertical-timeline-component/style.min.css';
import '../assets/styles/Timeline.scss';
// import { motion } from 'framer-motion';

const timelineData = [
  {
    date: "2025",
    title: "EO Engineer for Research into Financial Regulation",
    subtitle: "University of Strathclyde, Glasgow, UK",
    description: "Investigating the actual impact of Oil and Gas regulations on emissions in the North Sea. Working with the Financial Regulation Innovation Lab to provide technical background in Earth Observation and other related technologies."
  },
  {
    date: "2022 - Present",
    title: "Distributed Systems Engineer",
    subtitle: "SpaceDAO, International",
    description: "Developing the Open-Source project for finding consensus in Space with distributed technologies"
  },
  {
    date: "2024",
    title: "Visiting Researcher at the Φ-Lab",
    subtitle: "European Space Agency, Frascati, Italy",
    description: "Research into Decentralised Federated Learning for Crop Yield detection with Earth Observation"
  },
  {
    date: "2022 - Present",
    title: "PhD Candidate in Mechanical and Aerospace Engineering",
    subtitle: "University of Strathclyde, Glasgow, UK",
    description: "Research on Satellite-based Emergency Mapping Automation and Decentralisation with Distributed Ledger Technologies"
  },
  {
    date: "2017 - 2022",
    title: "Aero-Mechanical Engineering - Masters Degree",
    subtitle: "University of Strathclyde, Glasgow, UK",
    description: "Achieved Distinction in my Masters Degree in Aero-Mechanical Engineering"
  },
  {
    date: "2019 - 2020",
    title: "Aero-Mechanical Engineering - Exchange",
    subtitle: "Nanyang Technological University, Singapore",
    description: "Exchange as part of my Masters Degree in Aero-Mechanical Engineering"
  }
];

function Timeline() {
  return (
    <div id="history">
      <div className="items-container">
        <h1>Timeline</h1>
        <div className="timeline">
          <div className="outer">
            {timelineData.map((event, index) => (
              <div className="card">
                <div className="info">
                  <h3 className="title">{event.title}</h3>
                  <h4 className="subtitle">{event.date} | {event.subtitle}</h4>
                  <p className="description">{event.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Timeline;



